import React, { FunctionComponent } from "react"
import { Flex, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"
import { AiFillEye } from "react-icons/ai"

const StyledText = styled(Text)`
  margin: 0.5rem 0.4rem;
  line-height: 1.35rem;
`

interface IViewCountEyeProps {
  views: number
  textColor?: string
}

export const ViewCountEye: FunctionComponent<IViewCountEyeProps> = ({
  views,
  textColor = "grey",
}) => {
  return (
    <Flex
      alignItems="center"
      verticalAlign="middle"
      title="Blog post views"
      color={textColor}
    >
      <AiFillEye width="100px" /> <StyledText>{views}</StyledText>
    </Flex>
  )
}

export default ViewCountEye
