import React, { FunctionComponent, useEffect, useState } from "react"
import { Box, Flex } from "@chakra-ui/react"
import { AllPosts, PostNode } from "../../types/PostOverview"
import PreviewCard from "./PreviewCard"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { graphql, useStaticQuery } from "gatsby"

interface IMorePostsTilesProps {
  skipByTitle?: string
  totalPostsToShow?: number
}

export const MorePostsTiles: FunctionComponent<IMorePostsTilesProps> = ({
  skipByTitle,
  totalPostsToShow = 100000,
}) => {
  let totalPostsCurrentlyShowing = 0

  const [tileWidth, setTileWidth] = useState<"100%" | "47%" | "30%">("47%")
  const [tileMargin, setTileMargin] = useState<string>("0 -2rem")

  const { width } = useWindowDimensions()

  const data: AllPosts = useStaticQuery(graphql`
    query recentPosts {
      allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
        edges {
          node {
            id
            frontmatter {
              path
              title
              secondaryTitle
              author
              authorTwitter
              date
              description
              tags
              time
              featuredImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt
          }
        }
      }
      allTotalPageViews {
        edges {
          node {
            count
            id
            path
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (width >= 950) {
      setTileWidth("30%")
      setTileMargin("0 -2rem")
    } else if (width < 620) {
      setTileWidth("100%")
      setTileMargin("0 0")
    } else {
      setTileWidth("47%")
      setTileMargin("0 -0.5rem")
    }
  }, [width])

  return (
    <Flex
      width="100"
      flexWrap="wrap"
      justifyContent="center"
      margin={tileMargin}
      marginBottom="2rem"
    >
      {data.allMarkdownRemark.edges.map((post: PostNode, index) => {
        console.log("POST: ", post)

        const blogViews = data.allTotalPageViews.edges.find(elem => {
          if (elem.node.path === post.node.frontmatter.path) {
            return elem
          }
        })
        post.node.views = blogViews?.node.count
        if (
          (!skipByTitle || post.node.frontmatter.title !== skipByTitle) &&
          totalPostsCurrentlyShowing < totalPostsToShow
        ) {
          totalPostsCurrentlyShowing++
          return (
            <Box
              key={index}
              style={{
                margin: "0.5rem",
                flexBasis: tileWidth,
                justifyContent: "center",
              }}
            >
              <PreviewCard post={post} />
            </Box>
          )
        }
      })}
    </Flex>
  )
}

export default MorePostsTiles
