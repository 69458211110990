import React, { FunctionComponent, useState } from "react"
import { PostNode } from "../../types/PostOverview"
import styled from "@emotion/styled"
import {
  cardRadius,
  colors,
  hoverScaleEffect,
  shadows,
} from "../../theme/theme"
import { Flex, Heading, Text, Image, Spacer, Box } from "@chakra-ui/react"
import { Link } from "gatsby"
import ViewCountEye from "../ViewCountEye"
import LoadingSkeleton from "../LoadingSkeleton"
import { formattedDate } from "../../utils/formattedDate"

const TextContainer = styled.div`
  padding: 0.5rem;
  overflow: hidden;
  text-overflow: clip;
`

const StyledText = styled(Text)`
  margin: 0.5rem 0;
  line-height: 1.35rem;
  color: ${colors.grey.mediumDark};
`

const imageHeight = "8rem"

const ImageContainer = styled(Flex)`
  height: imageHeight;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
`

const StyledContainer = styled.div`
  height: 26rem;
  border-radius: ${cardRadius};
  overflow: hidden;
  ${hoverScaleEffect}
  background-color: ${colors.grey.light};

  :hover {
    box-shadow: ${shadows.containerShadow};
    background-color: ${colors.white};
  }
`

interface IPreviewCardProps {
  post: PostNode
}

export const PreviewCard: FunctionComponent<IPreviewCardProps> = ({ post }) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)

  return (
    <Link to={post.node.frontmatter.path} title={post.node.frontmatter.title}>
      <StyledContainer>
        <ImageContainer>
          <Image
            src={post.node.frontmatter.featuredImage?.childImageSharp.fluid.src}
            title={post.node.frontmatter.title}
            width="100%"
            objectFit="cover"
            height={imageHeight}
            style={{ display: `${imageLoaded ? "" : "none"}` }}
            onLoad={() => {
              setImageLoaded(true)
            }}
          />
          {!imageLoaded && (
            <LoadingSkeleton
              height={imageHeight}
              width="100%"
              radius="cardRadius"
            />
          )}
        </ImageContainer>
        <TextContainer style={{ height: "11.4rem", overflow: "hidden" }}>
          <Heading as="h2" size="sm" color={colors.grey.dark}>
            {post.node.frontmatter.title}{" "}
            {post.node.frontmatter.secondaryTitle && (
              <span style={{ color: colors.grey.mediumDark }}>
                {" "}
                - {post.node.frontmatter.secondaryTitle}
              </span>
            )}
          </Heading>
          <StyledText>{post.node.frontmatter.description}</StyledText>
        </TextContainer>
        <TextContainer style={{ paddingBottom: 0 }}>
          <StyledText
            style={{
              color: colors.grey.medium,
              marginBottom: 0,
            }}
          >
            {post.node.frontmatter.time} min read
          </StyledText>
        </TextContainer>
        <TextContainer style={{ marginBottom: "0", paddingTop: 0 }}>
          <Flex
            width="100%"
            alignItems="center"
            margin="0 auto"
            verticalAlign="bottom"
          >
            <ViewCountEye
              views={post.node.views ? post.node.views : 0}
              textColor={colors.grey.medium}
            />

            <Spacer />
            <StyledText
              style={{
                color: colors.grey.medium,
              }}
            >
              {formattedDate(post.node.frontmatter.date)}
            </StyledText>
          </Flex>
        </TextContainer>
      </StyledContainer>
    </Link>
  )
}

export default PreviewCard
